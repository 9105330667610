import BaseDialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';

import BaseB2bImage from '@/assets/B2B.svg';

export const IconWrapper = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    padding: '0 15px',
  },
}));

export const Text = styled('div')(() => ({
  fontSize: 24,
  fontWeight: 500,
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 1.17,
  letterSpacing: 0.47,
  textAlign: 'center',
}));

export const ButtonWrapper = styled('div')(({ theme }) => ({
  padding: '0 50px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',

  [theme.breakpoints.down('md')]: {
    padding: 0,
  },
}));

export const B2bImage = styled(BaseB2bImage)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    height: 'auto',
  },
}));

export const DialogContent = styled(BaseDialogContent)(({ theme }) => ({
  width: '100%',
  height: '100%',
  padding: '41px 31px 43px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',

  [theme.breakpoints.down('md')]: {
    padding: '36px 15px 39px',
  },
}));
